<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="24%"
      :before-close="btnClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div class="import_header">
        <div class="title">导出</div>
        <div @click="btnClose()" class="close">关闭</div>
      </div>
      <div class="import_content">
        <div class="import_item">
          <div class="select_year">
            <!-- <div v-if="filetype !== '1-1-1'" class="select_box1">
              <div class="text">请先选择导入年度：</div>
              <div class="box">
                <div :class="['box_arrow', notAllow1 ? 'box_arrow1' : '']" @click="handlePrev">
                  <i class="el-icon-arrow-left" style="color:#4E5969;"></i>
                </div>
                <div class="my_year">{{ year }}年度</div>
                <div :class="['box_arrow', notAllow2 ? 'box_arrow2' : '']" @click="handleNext">
                  <i class="el-icon-arrow-right" style="color:#4E5969;"></i>
                </div>
              </div>
            </div> -->

            <div class="select_box2">
              <div class="text">请先选择行政区域：</div>
              <div class="box">
                <el-form
                  :inline="true"
                  :model="searchObj"
                  :rules="rules"
                  ref="form"
                  style="align-items: center;margin-bottom: 4px;"
                  class="user-search flex-between"
                  @submit.native.prevent
                >
                  <el-form-item prop="OrgId">
                    <el-select
                      filterable
                      v-model="searchObj.OrgId"
                      clearable
                      @change="handleChangeOrgId"
                      size="mini"
                      placeholder="请选择行政区域"
                    >
                      <el-option v-for="item in treeData" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>

          <div class="import_opration">
            <el-button type="primary" size="small" plain @click="btnClose()">取消</el-button>
            <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { sysFileInfoUpload, sysFileInfoDownload } from '@/api/modular/system/fileManage'
import {
  _ImportDevelopment,
  _ImportPopulation
} from '@/api/modular/postImmigrationService/HomePage/socialDevelopment.js'

import {
  _ImportProjectStatistic,
  _ImportProject
} from '@/api/modular/postImmigrationService/HomePage/hydropowerProjects.js'

import { _ImportImplementation } from '@/api/modular/postImmigrationService/policy/basicSituation.js'
import { _ImportAdvocacy } from '@/api/modular/postImmigrationService/policy/policyPreaching.js'
import { _PlanningSituationImport } from '@/api/modular/postImmigrationService/policy/mediumSized.js'
import { _DirectFundsImport } from '@/api/modular/postImmigrationService/policy/subsidyFunds.js'
import { _ProjectFieldworkImport } from '@/api/modular/postImmigrationService/policy/fieldInvestigation.js'
import { _PorjectTypicalSpotChecksImport } from '@/api/modular/postImmigrationService/policy/typicalSpotCheck.js'
import { _BudgetExpenditureImport } from '@/api/modular/postImmigrationService/monitorAndTheUse/useOfFunds.js'
import { _DisposableIncomeImport } from '@/api/modular/postImmigrationService/monitorAndEvaluate/disposableIncome.js'
import { _ProductionLivingImport } from '@/api/modular/postImmigrationService/monitorAndEvaluate/changeSituation.js'
import { _SampleHouseholdStatisticImport } from '@/api/modular/postImmigrationService/monitorAndEvaluate/ImmigrationStatistics.js'
import { _SampleHouseholdImport } from '@/api/modular/postImmigrationService/monitorAndEvaluate/sampleHouseholds.js'
import { _InformationImport } from '@/api/modular/postImmigrationService/workingCondition/InputSituation.js'
import { _InvestmentImport } from '@/api/modular/postImmigrationService/workingCondition/verificationOfData.js'
import { _ProgressReportImport } from '@/api/modular/postImmigrationService/expenditureProgress/supportFund.js'
import { _ProjectInventoryImport } from '@/api/modular/postImmigrationService/policy/inventoryList.js'
import { _DemographicChangeImport } from '@/api/modular/postImmigrationService/monitorAndEvaluate/naturalChange.js'
import { _ApprovedImmigrationImport } from '@/api/modular/postImmigrationService/monitorAndEvaluate/newApproval.js'
import { _QuestionImport } from '@/api/modular/postImmigrationService/workingCondition/listOfQuestions.js'

import { _getLevelRegion } from '@/api/keepHome'

import templateTree from '@/components/templateView/templateTree.vue'

export default {
  name: 'HcYmhfImportExcel',

  data() {
    return {
      dialogVisible: false,
      year: null,
      years: [],
      filetype: '',
      notAllow1: false,
      notAllow2: false,
      treeData: [],
      dck: [],
      searchObj: {
        OrgId: null,
        OrgName: '',
        OrgPids: ''
      },
      rules: {
        OrgId: [{ required: true, message: '请选择行政区域', trigger: 'blur' }]
      }
    }
  },

  mounted() {},
  components: {
    templateTree
  },

  methods: {
    getLevelRegion(val) {
      let params = {
        startLevel: 2,
        endLevel: 2,
        inclusionLevel: false
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.treeData = res.data
          let provincialCenterId = this.treeData[0].id //默认展开第一个节点
          this.dck = [provincialCenterId]
          this.downloadObj = this.treeData[0]
        }
      })
    },

    handleChangeOrgId(val) {
      let obj = this.treeData.find(ele => ele.id == val)
      this.searchObj.OrgId = obj?.id
      this.searchObj.OrgName = obj?.name
      this.searchObj.OrgPids = obj?.pids
    },

    view() {
      this.getLevelRegion()
      this.dialogVisible = true
    },
    btnClose() {
      this.searchObj = {
        OrgId: null,
        OrgName: '',
        OrgPids: ''
      }

      this.dialogVisible = false
    },
    handlePrev() {
      let currentYear = this.year
      let currentYearIndex = this.years.findIndex(function(item) {
        return item.value === currentYear
      })

      if (currentYearIndex > 0) {
        this.notAllow1 = false
        this.notAllow2 = true
        currentYearIndex--
      } else {
        currentYearIndex = 0
        this.notAllow1 = true
        this.notAllow2 = false
      }
      this.year = this.years[currentYearIndex]?.value
    },
    handleNext() {
      let currentYear = this.year
      let currentYearIndex = this.years.findIndex(function(item) {
        return item.value === currentYear
      })
      if (currentYearIndex < this.years.length - 1) {
        this.notAllow2 = false
        this.notAllow1 = true
        currentYearIndex++
      } else {
        currentYearIndex = this.years.length - 1
        this.notAllow2 = true
        this.notAllow1 = false
      }
      this.year = this.years[currentYearIndex]?.value
    },
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$emit('refreshExport', this.searchObj)
          this.btnClose()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
.upload_demo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
// 弹框样式
::v-deep .el-dialog__header {
  padding: 0 0;
}

::v-deep .el-dialog__body {
  padding: 10px 0;

  font-family: PingFangSC-Bold-Bold;

  .import_header {
    height: 0.6rem;
    padding: 0 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dcdfe6;

    & > .title {
      font-size: 0.25rem;
      font-weight: 600;
      line-height: 0.35rem;
      letter-spacing: 0px;
      color: #1d2129;
    }

    & > .close {
      font-size: 0.1875rem;
      font-weight: normal;
      line-height: 0.275rem;
      text-align: right;
      align-items: center;
      letter-spacing: 0px;
      color: #165dff;
      cursor: pointer;
    }
  }

  .import_content {
    padding: 0 0.2rem;
    margin: 0.15rem auto;
    max-height: 600px;
    overflow: auto;

    & > .import_item {
      width: 100%;
      padding: 0.1875rem;
      background: #f8f9fd;
      margin-bottom: 0.125rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & > .title {
        margin-bottom: 0.125rem;
      }

      & > .select_year {
        width: 100%;
        padding: 0.1875rem;

        margin-bottom: 0.125rem;

        & > .select_box1 {
          display: flex;
          align-items: center;
          margin-bottom: 0.125rem;
          & > .text {
            font-size: 12px;
            font-weight: normal;
            line-height: 28px;
            letter-spacing: 0px;
            color: #1d2129;
          }

          & > .box {
            display: flex;
            align-items: center;
            & > .box_arrow {
              background: #f7f8f7;
              padding: 2px 5px;
              border-radius: 4px;
              box-sizing: border-box;
              border: 1px solid #cccccc;
              cursor: pointer;
            }

            & > .box_arrow1 {
              cursor: not-allowed !important;
            }
            & > .box_arrow2 {
              cursor: not-allowed !important;
            }

            & > .my_year {
              font-family: PingFang SC;
              font-size: 16px;
              font-weight: bold;
              line-height: 28px;
              letter-spacing: 0px;
              text-decoration: underline;
              color: #3f8cff;
              margin: 0 8px;
            }
          }
        }

        & > .select_box2 {
          display: flex;
          align-items: center;
          margin-bottom: 0.125rem;
          & > .text {
            font-size: 12px;
            font-weight: normal;
            line-height: 28px;
            letter-spacing: 0px;
            color: #1d2129;
          }

          & > .box {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .import_opration {
    padding: 10px;
    align-self: flex-end;
  }
}

::v-deep .el-divider--horizontal {
  margin: 0.15rem 0 !important;
}

::v-deep .el-textarea__inner {
  outline: none;
  background: #f2f3f5 !important;
}
</style>
